import { apiAddress } from './config.js';
import { handleMissingResponse } from './response.js';
import { siteLang, translate } from '../helpers/translations.js';
import { bugsnagClient } from '@mrhenry/wp--bugsnag-config';

// Get a list of Activities to fill up a selectbox
export const getActivities = () => {
	// Set headers if needed
	const headers = new Headers();
	const url = new URL( '/api/activities', apiAddress );
	const search = new URLSearchParams();
	search.append( 'language', siteLang() );
	url.search = search.toString();

	// Create a request with a url and config as needed
	const request = new Request( url, {
		method: 'GET',
		headers: headers,
	} );

	// Do the request
	return fetch( request ).then( ( response ) => {

		// Handle HTTP errors
		if ( !response.ok ) {
			// just throw so we can handle in the catch block later
			throw new Error( `GET /api/activities : ${response.status} ${response.statusText}` );
		}

		// this might error but we handle in the catch block later
		return response.json();
	} ).then( ( data ) => {
		handleMissingResponse( data );

		if ( !data || !data.response || !data.response.activities || !data.response.activities.length ) {
			return [];
		}

		const uniqueActivities = {};
		const activities = [];
		for ( let i = 0; i < data.response.activities.length; i++ ) {
			const activity = data.response.activities[i];

			const name = activity.name;
			if ( !uniqueActivities[name] ) {
				uniqueActivities[name] = {
					activities: [],
					activity_id: `grouped-activity--${i}`,
					name: translate( activity, 'name' ),
				};
			}

			uniqueActivities[name].activities.push( {
				activity_id: `${activity.activity_id}`,
				room_id: `${activity.room_id}`,
			} );
		}

		for ( const key in uniqueActivities ) {
			if ( !uniqueActivities.hasOwnProperty( key ) ) {
				continue;
			}

			activities.push( uniqueActivities[key] );
		}

		activities.sort( ( a, b ) => {
			if ( a.name !== b.name ) {
				return a.name < b.name;
			}

			if ( a.activity_id < b.activity_id ) {
				return -1;
			}

			if ( a.activity_id > b.activity_id ) {
				return 1;
			}

			return 0;
		} );

		return activities;
	} ).catch( ( e ) => {
		// Handle errors :
		// - connection errors
		// - HTTP/Server errors
		// - JSON decoding errors

		// report the error
		bugsnagClient.notify( e );
		// rethrow
		throw e;
	} );
};
