import { CloneList } from '../base/clone-list.js';
import { siteLang, months } from '../../helpers/translations.js';

class MrSummaryTimeSlotList extends CloneList {

	constructor() {
		// If you define a constructor, always call super() first!
		// This is specific to CE and required by the spec.
		super();

		this._stateHandler = ( e ) => {
			if ( !e || !e.detail || !e.detail.submit_data ) {
				return;
			}

			const state = e.detail.submit_data;

			if ( !state.periods ) {
				return;
			}

			this.setAttribute( 'value', state.periods );
		};
	}

	// Life cycle
	connectedCallback() {
		this._addEventListeners();
	}

	disconnectedCallback() {
		this._removeEventListeners();
	}

	_addEventListeners() {
		window.addEventListener( 'rent-space:state-change', this._stateHandler );
	}

	_removeEventListeners() {
		window.removeEventListener( 'rent-space:state-change', this._stateHandler );
	}

	update() {
		const data = this.value;
		if ( !data ) {
			return;
		}

		const locale = siteLang();

		this.resize( data.length, ( i ) => {
			let start = '';
			let end = '';

			if ( data[i].start_time ) {
				const startTime = new Date( data[i].start_time );

				start = `${startTime.getDate()} ${months[locale][startTime.getMonth()]} ${startTime.getFullYear()}`;

				start = start + ' ' + ( '0' + startTime.getHours() ).slice( -2 ) + ':' + ( '0' + startTime.getMinutes() ).slice( -2 );
			}

			if ( data[i].end_time ) {
				const endTime = new Date( data[i].end_time );

				end = `${endTime.getDate()} ${months[locale][endTime.getMonth()]} ${endTime.getFullYear()}`;

				end = end + ' ' + ( '0' + endTime.getHours() ).slice( -2 ) + ':' + ( '0' + endTime.getMinutes() ).slice( -2 );
			}

			return {
				'span.js-timeslot-start': [
					{
						innerHTML: start,
					},
				],
				'span.js-timeslot-end': [
					{
						innerHTML: end,
					},
				],
			};
		} );
	}
}

customElements.define( 'mr-summary-time-slot-list', MrSummaryTimeSlotList );
