import { getRoomName } from '../../helpers/room-dynamic-data.js';

class MrRoomName extends HTMLElement {

	constructor() {
		super();

		this._stateHandler = ( e ) => {
			if ( !e || !e.detail || !e.detail.step_room ) {
				return;
			}

			const state = e.detail.step_room;

			if ( !state.rooms ) {
				return;
			}

			let room_id;
			let subroom_id;

			state.rooms.forEach( ( room ) => {
				if ( !room.selected ) {
					return;
				}

				room_id = room.room_id;

				if ( room.sub_rooms && room.sub_rooms.length ) {
					room.sub_rooms.forEach( ( sub_room ) => {
						if ( !sub_room.selected ) {
							return;
						}

						subroom_id = sub_room.subroom_id;
					} );
				}
			} );

			if ( room_id ) {
				this.innerHTML = getRoomName( room_id, subroom_id );
			} else {
				this.innerHTML = '&nbsp;';
			}
		};
	}

	// Life cycle
	connectedCallback() {
		this._addEventListeners();
	}

	disconnectedCallback() {
		this._removeEventListeners();
	}

	_addEventListeners() {
		window.addEventListener( 'rent-space:state-change', this._stateHandler );
	}

	_removeEventListeners() {
		window.removeEventListener( 'rent-space:state-change', this._stateHandler );
	}
}

customElements.define( 'mr-room-name', MrRoomName );
