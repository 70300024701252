import { bugsnagClient } from '@mrhenry/wp--bugsnag-config';

// Reserve a timeslot in a certain room and add a bunch of metadata to the request
export const rentSpace = ( formData ) => {
	// Set headers if needed
	const headers = new Headers();
	headers.set( 'Content-Type', 'application/json' );
	const url = new URL( '/wp-json/rent-space/submit.json', window.location );

	let body = '';
	try {
		body = JSON.stringify( formData );
	} catch ( e ) {
		bugsnagClient.notify( e );

		return Promise.reject( e );
	}

	// Create a request with a url and config as needed
	const request = new Request( url, {
		method: 'POST',
		headers: headers,
		body: body,
	} );

	// Do the request
	return fetch( request ).then( ( response ) => {
		// this might error but we handle in the catch block later
		return response.json();
	} ).then( ( data ) => {
		if ( data && data.response ) {
			return data.response;
		}

		return data;
	} ).catch( ( e ) => {
		// Handle errors :
		// - connection errors
		// - HTTP/Server errors
		// - JSON decoding errors

		// report the error
		bugsnagClient.notify( e );
		// rethrow
		throw e;
	} );
};
