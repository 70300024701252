import '@mrhenry/wp--bugsnag-config';
import '@mrhenry/wp--autoplay-in-view';
import MrDeltaTime from '@mrhenry/wp--mr-delta-time';

import './modules/datetime';

import './rent-space/elements/controls/identification-verification.js';
import './rent-space/elements/controls/room-selector.js';

import './rent-space/elements/inputs/time-range.js';

import './rent-space/elements/lists/activity-list.js';
import './rent-space/elements/lists/facility-list.js';
import './rent-space/elements/lists/summary-facility-list.js';
import './rent-space/elements/lists/summary-time-slot-list.js';
import './rent-space/elements/lists/time-slot-list.js';

import './rent-space/elements/view/catering-price-list.js';
import './rent-space/elements/view/drinks-price-list.js';
import './rent-space/elements/view/room-capacity.js';
import './rent-space/elements/view/room-name.js';
import './rent-space/elements/view/room-price-list.js';
import './rent-space/elements/view/student-entrepreneurial-event.js';
import './rent-space/elements/view/summary.js';
import './rent-space/elements/view/terms-and-conditions.js';

import './rent-space/elements/form-step-progress';
import './rent-space/elements/rent-space-form.js';

customElements.define( 'mr-delta-time', MrDeltaTime );
