// A CloneList tries to solve the problem of rendering a list of items through Javascript.
// The goal is to not write HTML in javascript.
// "template" tags have issues when they contain Custom Elements and thus can't be used.
//
// A CloneList contains a "ul" and an "li"
// The "li" contains all the HTML needed
// The CloneList listens to changes on its "value" attribute
// If a change happens it will clear itself and clone the first "li"
// No HTML structure changes are done, only attributes are set.
// Combined with "span" and css "content" we can easily have dynamic lists with rich content.
//
export class CloneList extends HTMLElement {
	// Listen for changes on "value"
	static get observedAttributes() {
		return [
			'value',
		];
	}

	// CE boilerplate
	constructor() {
		// If you define a constructor, always call super() first!
		// This is specific to CE and required by the spec.
		super();
	}

	// override in sub class
	// triggered by changes on "value"
	update() {}

	// Override getAttribute to handle JSON values
	getAttribute( attr ) {
		if ( 'value' === attr ) {
			let data = null;
			try {
				const attrValue = super.getAttribute( 'value' );
				if ( '' !== attrValue ) {
					data = JSON.parse( attrValue );
				}

				return data;
			} catch ( err ) {
				console.warn( err );
			}

			return data;
		}

		return super.getAttribute( attr );
	}

	// Override setAttribute to handle JSON values
	setAttribute( attr, value ) {
		if ( this.disabled ) {
			return;
		}

		if ( 'value' === attr ) {
			const sanitized = JSON.stringify( value );

			if ( sanitized === super.getAttribute( 'value' ) ) {
				return;
			}

			if ( null === sanitized ) {
				super.setAttribute( attr, '' );

				return;
			}

			super.setAttribute( attr, sanitized );

			return;
		}

		super.setAttribute( attr, value );
	}

	// Implement disabled state
	get disabled() {
		return this.hasAttribute( 'disabled' );
	}

	// Implement disabled state
	set disabled( value ) {
		if ( value ) {
			this.setAttribute( 'disabled', '' );
		} else {
			this.removeAttribute( 'disabled' );
		}
	}

	// JS side of "value" attribute
	get value() {
		return this.getAttribute( 'value' );
	}

	// JS side of "value" attribute
	set value( value ) {
		this.setAttribute( 'value', value );
	}

	// Current length of the CloneList (how many "li's" are there?)
	get length() {
		return Array.from( this.querySelectorAll( 'ul > li' ) ).length;
	}

	// n is the size to which we resize
	// dataFn is a function : (<index>) => <data>
	//
	// Apply calls "applyOnListItem"
	resize( n, dataFn ) {
		const currentLength = this.length;
		const diff = n - currentLength;

		if ( 0 > diff ) {
			for ( let i = 0; i < Math.abs( diff ); i++ ) {
				this.removeListItem( currentLength - ( 1 + i ) );
			}
		} else if ( 0 < diff ) {
			this.addListItems( diff, dataFn );
		}

		for ( let i = 0; i < n; i++ ) {
			this.applyOnListItem( i, dataFn( i ) );
		}
	}

	// Get the closest "ul" child
	list() {
		return this.querySelector( 'ul' );
	}

	// Get the nth "li" of the closes "ul" child
	listItem( i = 0 ) {
		return this.querySelector( `ul > li:nth-child(${i + 1})` );
	}

	// remove a single list item without re-rendering the entire list
	removeListItem( i = 0 ) {
		if ( 0 === i && 2 > this.length ) {
			return;
		}

		const item = this.listItem( i );
		if ( item && item.parentNode ) {
			item.parentNode.removeChild( item );
		}
	}

	addListItems( n, dataFn ) {
		if ( 1 > n ) {
			return;
		}

		const items = Array.from( this.querySelectorAll( 'ul > li' ) );
		if ( !items || 0 === items.length ) {
			return;
		}

		const first = items[0].cloneNode( true );
		if ( first.hasAttributes() ) {
			first.setAttribute( 'id', '' );
			first.setAttribute( 'value', '' );
		}

		const list = this.list();
		const start = items.length;
		const end = start + n;

		for ( let i = start; i < end; i++ ) {
			const clone = first.cloneNode( true );
			if ( dataFn ) {
				this.apply( clone, dataFn( i ) );
			}

			list.appendChild( clone );
		}
	}

	// Sets attributes on the nth "li"
	// This does a querySelector inside the "li" looking for attributes
	// e.g. applyOnListItem(0, { 'span[data-name]': [{ 'data-name': 'foo' }] }) will set "foo" as the "data-name" attribute value on an element inside the first "li" which already has an "data-name" attribute.
	applyOnListItem( i = 0, data = {} ) {
		const item = this.listItem( i );
		if ( !item ) {
			return;
		}

		this.apply( item, data );
	}

	apply( item, data = {} ) {
		for ( const key in data ) {
			if ( !data.hasOwnProperty( key ) ) {
				continue;
			}

			const dataEntry = data[key];
			if ( !Array.isArray( dataEntry ) ) {
				continue;
			}

			const subItems = Array.from( item.querySelectorAll( `${key}` ) );
			if ( !subItems || !subItems.length ) {
				continue;
			}

			const length = Math.min( dataEntry.length, subItems.length );

			for ( let i = 0; i < length; i++ ) {
				for ( const property in dataEntry[i] ) {
					if ( !dataEntry[i].hasOwnProperty( property ) ) {
						continue;
					}

					if ( 'innerHTML' === property ) {
						if ( null === dataEntry[i][property] ) {
							subItems[i].innerHTML = '';
						} else {
							subItems[i].innerHTML = dataEntry[i].innerHTML;
						}

						continue;
					}

					if ( 'checked' === property ) {
						if ( dataEntry[i][property] ) {
							subItems[i].checked = true;
						} else {
							subItems[i].checked = false;
						}

						continue;
					}

					if ( null === dataEntry[i][property] ) {
						subItems[i].removeAttribute( property );
					} else {
						subItems[i].setAttribute( property, dataEntry[i][property] );

						if ( 'value' === property ) {
							subItems[i].value = dataEntry[i][property];
						}
					}
				}
			}
		}
	}

	// Listen for changes on "value"
	attributeChangedCallback( attrName ) {
		if ( 'value' === attrName ) {
			this.update();

			return;
		}
	}
}
