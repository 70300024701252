class MrRoomSelectorControl extends HTMLElement {
	constructor() {
		// If you define a constructor, always call super() first!
		// This is specific to CE and required by the spec.
		super();

		this._stateHandler = ( e ) => {
			if ( !e || !e.detail || !e.detail.step_room ) {
				return;
			}

			if ( 'undefined' === typeof e.detail.step_room.rooms ) {
				return;
			}

			const rooms = e.detail.step_room.rooms;

			const activities = e.detail.step_room.selected_activities_group?.activities ?? [];

			this.update( rooms, activities );
		};
	}

	// Life cycle
	connectedCallback() {
		this._addEventListeners();
	}

	disconnectedCallback() {
		this._removeEventListeners();
	}

	_addEventListeners() {
		window.addEventListener( 'rent-space:state-change', this._stateHandler );
	}

	_removeEventListeners() {
		window.removeEventListener( 'rent-space:state-change', this._stateHandler );
	}

	get form() {
		const form = this.closest( 'form' );
		if ( !form ) {
			return null;
		}

		return form;
	}

	update( rooms, selected_activities ) {
		if ( !rooms || !rooms.length ) {
			return;
		}

		for ( let i = 0; i < rooms.length; i++ ) {
			const room = rooms[i];
			const featured = !!( selected_activities.find( ( selected_activity ) => {
				return selected_activity.room_id === room.room_id;
			} ) );

			if ( room.sub_rooms && room.sub_rooms.length ) {
				for ( let j = 0; j < room.sub_rooms.length; j++ ) {
					const sub_room = room.sub_rooms[j];

					const roomView = this.querySelector( `[room-id="${room.room_id}"][subroom-id="${sub_room.subroom_id}"]` );
					if ( roomView ) {
						if ( featured ) {
							roomView.setAttribute( 'data-featured', '' );
						} else {
							roomView.removeAttribute( 'data-featured' );
						}
					}
				}
			} else {
				const roomView = this.querySelector( `[room-id="${room.room_id}"]` );
				if ( roomView ) {
					if ( featured ) {
						roomView.setAttribute( 'data-featured', '' );
					} else {
						roomView.removeAttribute( 'data-featured' );
					}
				}
			}
		}
	}
}

customElements.define( 'mr-room-selector-control', MrRoomSelectorControl );
