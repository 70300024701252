class MrIdentificationVerificationControl extends HTMLElement {
	constructor() {
		// If you define a constructor, always call super() first!
		// This is specific to CE and required by the spec.
		super();

		this._changeHandler = ( e ) => {
			if ( null === e || null === e.target ) {
				return;
			}

			if ( 'vat' !== e.target.getAttribute( 'name' ) && 'rrnr' !== e.target.getAttribute( 'name' ) ) {
				return;
			}

			if ( !e.target.form || !e.target.form.vat || !e.target.form.rrnr ) {
				return;
			}

			const vat = e.target.form.vat;
			const rrnr = e.target.form.rrnr;

			if ( !vat.value && !rrnr.value ) {
				vat.required = true;
				rrnr.required = true;
			} else if ( vat.value && rrnr.value ) {
				vat.required = false;
				rrnr.required = false;
			} else if ( vat.value ) {
				vat.required = true;
				rrnr.required = false;
			} else if ( rrnr.value ) {
				vat.required = false;
				rrnr.required = true;
			}

			this.querySelectorAll( `label[for="${vat.id}"]` ).forEach( ( label ) => {
				if ( vat.required && !label.textContent.trim().endsWith( '*' ) ) {
					label.textContent = label.textContent + '*';
				} else if ( !vat.required && label.textContent.trim().endsWith( '*' ) ) {
					label.textContent = label.textContent.trim().slice( 0, -1 );
				}
			} );

			this.querySelectorAll( `label[for="${rrnr.id}"]` ).forEach( ( label ) => {
				if ( rrnr.required && !label.textContent.trim().endsWith( '*' ) ) {
					label.textContent = label.textContent + '*';
				} else if ( !rrnr.required && label.textContent.trim().endsWith( '*' ) ) {
					label.textContent = label.textContent.trim().slice( 0, -1 );
				}
			} );

			return;
		};
	}

	// Life cycle
	connectedCallback() {
		this._addEventListeners();
	}

	disconnectedCallback() {
		this._removeEventListeners();
	}

	_addEventListeners() {
		this.addEventListener( 'change', this._changeHandler );
	}

	_removeEventListeners() {
		this.removeEventListener( 'change', this._changeHandler );
	}
}

customElements.define( 'mr-identification-verification-control', MrIdentificationVerificationControl );
