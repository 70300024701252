import { apiAddress } from './config.js';
import { getActivities } from './activities.js';
import { getFacilities } from './facilities.js';
import { handleMissingResponse } from './response.js';
import { siteLang, translate } from '../helpers/translations.js';
import { bugsnagClient } from '@mrhenry/wp--bugsnag-config';

// Get a list of all the rooms that can be booked
export const getRooms = ( withRelatedData = false ) => {
	// Set headers if needed
	const headers = new Headers();
	const url = new URL( '/api/rooms', apiAddress );
	const search = new URLSearchParams();
	search.append( 'language', siteLang() );
	url.search = search.toString();

	// Create a request with a url and config as needed
	const request = new Request( url, {
		method: 'GET',
		headers: headers,
	} );

	// Do the request
	return fetch( request ).then( ( response ) => {

		// Handle HTTP errors
		if ( !response.ok ) {
			// just throw so we can handle in the catch block later
			throw new Error( `GET /api/rooms : ${response.status} ${response.statusText}` );
		}

		// this might error but we handle in the catch block later
		return response.json();
	} ).then( ( data ) => {
		handleMissingResponse( data );

		if ( !data || !data.response || !data.response.rooms ) {
			return [];
		}

		const rooms = [];
		for ( let i = 0; i < data.response.rooms.length; i++ ) {
			const room = data.response.rooms[i];

			const roomData = {
				room_id: `${room.room_id}`,
				sub_rooms: room.sub_rooms,
				capacity: room.capacity,
				name: translate( room, 'name' ),
				facilities: [],
				activities: [],
			};

			if ( roomData.sub_rooms && roomData.sub_rooms.length ) {
				roomData.sub_rooms = roomData.sub_rooms.map( ( sub_room ) => {
					sub_room.subroom_id = `${sub_room.subroom_id}`;

					return sub_room;
				} );
			}

			rooms.push( roomData );
		}

		rooms.sort( ( a, b ) => {
			if ( a.room_id < b.room_id ) {
				return -1;
			}

			if ( a.room_id > b.room_id ) {
				return 1;
			}

			return 0;
		} );

		if ( withRelatedData && rooms.length ) {
			return roomsWithRelatedData( rooms );
		}

		return rooms;
	} ).catch( ( e ) => {
		// Handle errors :
		// - connection errors
		// - HTTP/Server errors
		// - JSON decoding errors

		// report the error
		bugsnagClient.notify( e );
		// rethrow
		throw e;
	} );
};

const roomsWithRelatedData = ( rooms ) => {
	const promises = [];

	promises.push( getFacilities().then( ( facilities ) => {
		for ( let i = 0; i < facilities.length; i++ ) {
			const facility = facilities[i];

			if ( facility.room_id ) {
				rooms.forEach( ( room ) => {
					if ( `${room.room_id}` === `${facility.room_id}` ) {
						room.facilities.push( {
							facility_id: `${facility.facility_id}`,
							name: translate( facility, 'name' ),
						} );
					}
				} );
			}
		}

		return true;
	} ) );

	promises.push( getActivities().then( ( activities ) => {
		for ( let i = 0; i < activities.length; i++ ) {
			const activity = activities[i];

			if ( activity.room_id ) {
				rooms.forEach( ( room ) => {
					if ( `${room.room_id}` === `${activity.room_id}` ) {
						room.activities.push( {
							activity_id: `${activity.activity_id}`,
							name: translate( activity, 'name' ),
						} );
					}
				} );
			}
		}

		return true;
	} ) );

	return Promise.all( promises ).then( () => {
		return rooms;
	} );
};
