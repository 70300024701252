import { apiAddress } from './config.js';
import { handleMissingResponse } from './response.js';
import { siteLang, translate } from '../helpers/translations.js';
import { bugsnagClient } from '@mrhenry/wp--bugsnag-config';

// Get a list of provisions
export const getFacilities = () => {
	// Set headers if needed
	const headers = new Headers();
	const url = new URL( '/api/facilities', apiAddress );
	const search = new URLSearchParams();
	search.append( 'language', siteLang() );
	url.search = search.toString();

	// Create a request with a url and config as needed
	const request = new Request( url, {
		method: 'GET',
		headers: headers,
	} );

	// Do the request
	return fetch( request ).then( ( response ) => {

		// Handle HTTP errors
		if ( !response.ok ) {
			// just throw so we can handle in the catch block later
			throw new Error( `GET /api/facilities : ${response.status} ${response.statusText}` );
		}

		// this might error but we handle in the catch block later
		return response.json();
	} ).then( ( data ) => {
		handleMissingResponse( data );

		if ( !data || !data.response || !data.response.facilities || !data.response.facilities.length ) {
			return [];
		}

		const facilities = [];
		for ( let i = 0; i < data.response.facilities.length; i++ ) {
			const facility = data.response.facilities[i];

			facilities.push( {
				facility_id: `${facility.facility_id}`,
				room_id: `${facility.room_id}`,
				name: translate( facility, 'name' ),
			} );
		}

		facilities.sort( ( a, b ) => {
			if ( a.name !== b.name ) {
				return a.name < b.name;
			}

			if ( a.facility_id < b.facility_id ) {
				return -1;
			}

			if ( a.facility_id > b.facility_id ) {
				return 1;
			}

			return 0;
		} );

		return facilities;
	} ).catch( ( e ) => {
		// Handle errors :
		// - connection errors
		// - HTTP/Server errors
		// - JSON decoding errors

		// report the error
		bugsnagClient.notify( e );
		// rethrow
		throw e;
	} );
};
