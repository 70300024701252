import { CloneList } from '../base/clone-list.js';

class MrSummaryFacilityList extends CloneList {

	constructor() {
		// If you define a constructor, always call super() first!
		// This is specific to CE and required by the spec.
		super();

		this._stateHandler = ( e ) => {
			if ( !e || !e.detail ) {
				return;
			}

			if ( !e.detail.step_room || !e.detail.step_room.rooms ) {
				return;
			}

			if ( !e.detail.submit_data || !e.detail.submit_data.facilities ) {
				return;
			}

			e.detail.step_room.rooms.forEach( ( room ) => {
				if ( !room.selected ) {
					return;
				}

				if ( !room.facilities ) {
					return;
				}

				this.facilities = room.facilities;
			} );

			this.setAttribute( 'value', e.detail.submit_data.facilities );
		};
	}

	// Life cycle
	connectedCallback() {
		this._addEventListeners();
	}

	disconnectedCallback() {
		this._removeEventListeners();
	}

	_addEventListeners() {
		window.addEventListener( 'rent-space:state-change', this._stateHandler );
	}

	_removeEventListeners() {
		window.removeEventListener( 'rent-space:state-change', this._stateHandler );
	}

	update() {
		const data = this.value;
		if ( !data ) {
			return;
		}

		if ( !this.facilities ) {
			return;
		}

		this.resize( data.length, ( i ) => {
			const facility = this.facilities.find( ( e ) => {
				return `${e.facility_id}` === `${data[i]}`;
			} );

			if ( !facility ) {
				return {
					'span.js-facility': [
						{
							innerHTML: '',
						},
					],
				};
			}

			return {
				'span.js-facility': [
					{
						innerHTML: facility.name,
					},
				],
			};
		} );
	}
}

customElements.define( 'mr-summary-facility-list', MrSummaryFacilityList );
