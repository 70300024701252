import { getActivities } from '../api/activities.js';
import { getRooms } from '../api/rooms.js';
import { rentSpace } from '../api/rent-space.js';
import { siteLang } from '../helpers/translations.js';

class MrRentSpaceForm extends HTMLElement {
	constructor() {
		// If you define a constructor, always call super() first!
		// This is specific to CE and required by the spec.
		super();

		this._changeHandler = ( e ) => {
			if ( !e || !e.target ) {
				return;
			}

			const form = this.querySelector( 'form' );
			if ( !form ) {
				return;
			}

			// Not part of our form, probably a radio/checkbox hack
			if ( null === e.target.getAttribute( 'name' ) ) {
				return;
			}

			// Not part of our form, probably a radio/checkbox hack
			if ( e.target.getAttribute( 'form' ) !== form.getAttribute( 'id' ) ) {
				return;
			}

			this._data = new FormData( form );
			this._dataChanged();
		};

		this._submitHandler = ( e ) => {
			e.preventDefault();
			e.stopPropagation();

			const form = e.target;
			if ( !form ) {
				return;
			}

			if ( this.submitting ) {
				return;
			}

			this._setSubmitting();
			this._clearErrorMessage();

			rentSpace( this._state.submit_data ).then( ( resp ) => {
				if ( resp.has_warnings || resp.has_errors ) {
					this._setErrorMessage( resp.messages );
					this._clearSubmitting();

					return;
				}

				const endState = document.getElementById( 'rent-space-state--end' );
				endState.removeAttribute( 'hidden' );

				this.setAttribute( 'hidden', '' );

				window.scrollTo( 0, 0 );

			} ).catch( ( err ) => {
				console.warn( err );

				this._setErrorMessage();
				this._clearSubmitting( e.target );
			} );
		};

		this._clickHandler = ( e ) => {
			if ( !e.target.hasAttribute( 'submit-rent-space' ) ) {
				return;
			}

			this.classList.add( 'submit-attempted' );
		};
	}

	// Life cycle
	connectedCallback() {
		this._addEventListeners();
		requestAnimationFrame( () => {
			this._setupState();
		} );
	}

	disconnectedCallback() {
		this._removeEventListeners();
		this._destroyState();
	}

	_setupState() {
		this._state = {
			step_activity: {},
			step_confirm: {},
			step_personal: {},
			step_room: {},
			step_start: {},
			step_time: {},
			step_type: {},

			submit_data: {},
		};

		Promise.all(
			[
				getRooms( true ),
				getActivities(),
			]
		).then( ( results ) => {
			this._state.step_room.rooms = results[0];
			this._state.step_type.activities = results[1];

			this._stateChanged();
		} ).catch( ( e ) => {
			console.warn( e );
			this.fatalError = true;
			this._fatalErrorOccured();
		} );
	}

	_destroyState() {
		this._state = undefined;
	}

	_stateChanged() {
		if ( this.fatalError ) {
			return;
		}

		const event = new CustomEvent( 'rent-space:state-change', {
			detail: this._state,
			cancelable: false,
		} );

		window.dispatchEvent( event );
	}

	_dataChanged() {
		if ( this.fatalError ) {
			return;
		}

		if ( !this._state ) {
			return;
		}

		if ( !this._data ) {
			return;
		}

		if ( this._state.step_room && !this._state.step_room.rooms ) {
			console.log( 'I expected rooms data before _dataChanged gets called, here is the state of step_room : ', this._state.step_room );
		}

		if ( !this._state.step_room || !this._state.step_room.rooms ) {
			return;
		}

		// Reset some state before parsing the form

		// Reset selected room
		this._state.step_room.rooms.forEach( ( room ) => {
			room.selected = false;

			if ( room.sub_rooms && room.sub_rooms.length ) {
				room.sub_rooms.forEach( ( sub_room ) => {
					sub_room.selected = false;
				} );
			}
		} );

		// Reset chosen periods
		this._state.submit_data.periods = [];
		this._state.submit_data.facilities = [];

		this._state.step_room.selected_activities_group = null;
		const selected_activity_group_id = this._data.get( 'activity-group' );
		if ( selected_activity_group_id ) {
			const selected_activity_group = this._state.step_type.activities.find( ( activity_group ) => {
				if ( activity_group.activity_id === selected_activity_group_id ) {
					return true;
				}

				return false;
			} );

			this._state.step_room.selected_activities_group = selected_activity_group;
		}

		// Selected room
		// This is a special case as this contains 2 values compressed into one (cause we like freaking complex crap)
		const room_ids = this._data.get( 'room_ids' );
		let selected_room_id;
		let selected_subroom_id;

		if ( room_ids ) {
			const room_id_parts = room_ids.split( ':' );

			if ( room_id_parts.length ) {
				const room_id = room_id_parts[0];
				const subroom_id = room_id_parts[1] || '0';

				this._state.step_room.rooms.forEach( ( room ) => {
					if ( `${room.room_id}` === room_id ) {
						room.selected = true;
						selected_room_id = room_id;
						selected_subroom_id = '0';
					}

					if ( room.sub_rooms && room.sub_rooms.length ) {
						room.sub_rooms.forEach( ( sub_room ) => {
							if ( `${sub_room.subroom_id}` === subroom_id ) {
								sub_room.selected = true;
								selected_subroom_id = subroom_id;
							}
						} );
					}
				} );
			}
		}

		this._state.submit_data.activity = null;
		if ( selected_room_id && this._state.step_room.selected_activities_group && this._state.step_room.selected_activities_group.activities ) {
			const selected_activity = this._state.step_room.selected_activities_group.activities.find( ( x ) => {
				return x.room_id === selected_room_id;
			} );

			if ( selected_activity && selected_activity.activity_id ) {
				this._state.submit_data.activity = selected_activity.activity_id;
				this._state.step_confirm.activity_type_name = this._state.step_room.selected_activities_group.name;
			}
		}

		// Single fields
		this._state.submit_data.language = siteLang();
		this._state.submit_data.activity_description = this._data.get( 'activity_description' );
		this._state.submit_data.activity_name = this._data.get( 'activity_name' );
		this._state.submit_data.comments = this._data.get( 'comments' );
		this._state.submit_data.contact_educational_institution = this._data.get( 'contact_educational_institution' );
		this._state.submit_data.contact_email = this._data.get( 'contact_email' );
		this._state.submit_data.contact_firstname = this._data.get( 'contact_firstname' );
		this._state.submit_data.contact_lastname = this._data.get( 'contact_lastname' );
		this._state.submit_data.contact_phone = this._data.get( 'contact_phone' );
		this._state.submit_data.contact_studentnumber = this._data.get( 'contact_studentnumber' );
		this._state.submit_data.country = this._data.get( 'country' );
		this._state.submit_data.drinks = this._data.get( 'drinks' );
		this._state.submit_data.invoice_email = this._data.get( 'invoice_email' );
		this._state.submit_data.num_persons = this._data.get( 'num_persons' );
		this._state.submit_data.organizer = this._data.get( 'organizer' );
		this._state.submit_data.rrnr = this._data.get( 'rrnr' );
		this._state.submit_data.street = this._data.get( 'street' );
		this._state.submit_data.street_number = this._data.get( 'street_number' );
		this._state.submit_data.town = this._data.get( 'town' );
		this._state.submit_data.vat = this._data.get( 'vat' );
		this._state.submit_data.vatStatus = this._data.get( 'vatStatus' );
		this._state.submit_data.zip = this._data.get( 'zip' );
		this._state.submit_data.room_id = selected_room_id;
		this._state.submit_data.subrooms = [
			selected_subroom_id,
		];

		// Both fields are required by the API, but we allow a user to leave one empty if the other is set.
		const hasVat = !!this._data.get( 'vat' ).trim();
		const hasRR_NR = !!this._data.get( 'rrnr' ).trim();
		if ( hasVat && !hasRR_NR ) {
			this._state.submit_data.rrnr = 'x';
		} else if ( !hasVat && hasRR_NR ) {
			this._state.submit_data.vat = 'x';
		}

		// Special fields (blame Rombit)

		// 'is_organization' is true if the field is present on submit, not when the value is truthy
		if ( 'true' === this._data.get( 'is_organization' ) ) {
			this._state.submit_data.is_organization = true;
		} else {
			delete this._state.submit_data.is_organization;
		}

		// Dynamic fields
		for ( const [
			key,
			value,
		] of this._data.entries() ) {

			if (
				0 <= key.indexOf( 'from-date' ) ||
				0 <= key.indexOf( 'until-date' )
			) {
				const timeInfo = this.parseRepeatedInput( key );
				if ( !timeInfo ) {
					continue;
				}

				if ( !this._state.submit_data.periods[timeInfo.index] ) {
					this._state.submit_data.periods[timeInfo.index] = {};
				}

				if ( timeInfo.field === 'from-date' ) {
					this._state.submit_data.periods[timeInfo.index].start_time = value;
				} else if ( timeInfo.field === 'until-date' ) {
					this._state.submit_data.periods[timeInfo.index].end_time = value;
				}

				if ( this._state.submit_data.periods[timeInfo.index].start_time > this._state.submit_data.periods[timeInfo.index].end_time ) {
					[
						this._state.submit_data.periods[timeInfo.index].start_time,
						this._state.submit_data.periods[timeInfo.index].end_time,
					] = [
						this._state.submit_data.periods[timeInfo.index].end_time,
						this._state.submit_data.periods[timeInfo.index].start_time,
					];
				}

				continue;
			}

			if ( 0 <= key.indexOf( 'facilities' ) ) {
				const materialInfo = this.parseRepeatedInput( key );
				if ( !materialInfo ) {
					continue;
				}

				if ( value ) {
					this._state.submit_data.facilities.push( value );
				}

				continue;
			}
		}

		this._stateChanged();
	}

	parseRepeatedInput( name ) {
		const nameParts = name.split( '[' );
		if ( 2 !== nameParts.length ) {
			return;
		}

		const field = nameParts[0];
		const indexParts = nameParts[1].split( ']' );
		if ( !indexParts.length ) {
			return {
				field: field,
			};
		}

		const index = parseInt( indexParts[0], 10 );
		if ( Number.isNaN( index ) ) {
			return {
				field: field,
			};
		}

		return {
			field: field,
			index: index,
		};
	}

	_fatalErrorOccured() {
		const event = new CustomEvent( 'rent-space:fatal-error', {
			detail: {},
			cancelable: false,
		} );

		window.dispatchEvent( event );
	}

	_addEventListeners() {
		this.addEventListener( 'change', this._changeHandler );
		this.addEventListener( 'submit', this._submitHandler );
		this.addEventListener( 'click', this._clickHandler );
	}

	_removeEventListeners() {
		this.removeEventListener( 'change', this._changeHandler );
		this.removeEventListener( 'submit', this._submitHandler );
		this.removeEventListener( 'click', this._clickHandler );
	}

	_setErrorMessage( messages = null ) {
		const errArea = document.getElementById( 'submit-rent-space-error-messages' );
		if ( !errArea ) {
			return;
		}

		errArea.removeAttribute( 'hidden' );

		if ( messages && messages.length ) {
			const errMessages = [];
			messages.forEach( ( message ) => {
				errMessages.push( message.message );
			} );

			errArea.innerHTML = errMessages.join( '<br>' );

			return;
		}

		if ( 'nl' === siteLang() ) {
			errArea.innerHTML = 'Er heeft zich een onverwachte fout voorgedaan, probeer het later nogmaals.';
		} else {
			errArea.innerHTML = 'An unexpected error occured, please try again later.';
		}
	}

	_clearErrorMessage() {
		const errArea = document.getElementById( 'submit-rent-space-error-messages' );
		if ( !errArea ) {
			return;
		}

		errArea.setAttribute( 'hidden', '' );
		errArea.innerHTML = '';
	}

	_setSubmitting() {
		this.submitting = true;

		Array.from( this.querySelectorAll( 'button, input[type="submit"]' ) ).forEach( ( submit ) => {
			if ( submit.form !== this ) {
				return;
			}

			submit.disabled = true;
			submit.setAttribute( 'disabled', '' );
			submit.setAttribute( 'submitting', '' );
		} );
	}

	_clearSubmitting() {
		this.submitting = false;

		Array.from( this.querySelectorAll( 'button, input[type="submit"]' ) ).forEach( ( submit ) => {
			if ( submit.form !== this ) {
				return;
			}

			submit.disabled = false;
			submit.removeAttribute( 'disabled' );
			submit.removeAttribute( 'submitting' );
		} );
	}
}

customElements.define( 'mr-rent-space-form', MrRentSpaceForm );
