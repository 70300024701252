class MrRoomCapacity extends HTMLElement {

	constructor() {
		super();

		this._stateHandler = ( e ) => {
			if ( !e || !e.detail || !e.detail.step_room ) {
				return;
			}

			const state = e.detail.step_room;

			if ( !state.rooms ) {
				return;
			}

			state.rooms.forEach( ( room ) => {
				if ( !room.selected ) {
					return;
				}

				const input = this.querySelector( 'input' );
				if ( !input ) {
					return;
				}

				input.setAttribute( 'max', room.capacity );
			} );
		};
	}

	// Life cycle
	connectedCallback() {
		this._addEventListeners();
	}

	disconnectedCallback() {
		this._removeEventListeners();
	}

	_addEventListeners() {
		window.addEventListener( 'rent-space:state-change', this._stateHandler );
	}

	_removeEventListeners() {
		window.removeEventListener( 'rent-space:state-change', this._stateHandler );
	}
}

customElements.define( 'mr-room-capacity', MrRoomCapacity );
