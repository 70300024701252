export const translate = ( data, key ) => {
	const lang = siteLang();

	if ( 'name' === key ) {
		if ( 'nl' === lang ) {
			return data.name || '';
		} else if ( 'en' === lang ) {
			return data.name_en || data.name || '';
		}
	}

	return '';
};

let lang = '';
export const siteLang = () => {
	if ( !lang ) {
		lang = document.documentElement.getAttribute( 'lang' );
	}

	if ( 'en' === lang ) {
		return 'en';
	}

	// 'nl' is the default language, so we use it also as fallback here.
	return 'nl';
};

export const siteLocale = () => {
	if ( 'en' === siteLang() ) {
		return 'en-US';
	}

	return 'nl-BE';
};

export const months = {
	nl: [
		'januari',
		'februari',
		'maart',
		'april',
		'mei',
		'juni',
		'juli',
		'augustus',
		'september',
		'oktober',
		'november',
		'december',
	],
	en: [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	],
};
