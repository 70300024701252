class MrFormStepProgress extends HTMLElement {
	#observer: IntersectionObserver;

	#intersectionRatios: WeakMap<Element, number> = new WeakMap();

	constructor() {
		super();

		this.#observer = new IntersectionObserver( this.#intersectionHandler, {
			root: null,
			threshold: [
				0,
				0.2,
				0.4,
				0.6,
				0.8,
				1,
			],
		} );
	}

	connectedCallback() {
		requestAnimationFrame( () => {
			const elements = this.querySelectorAll( '[data-form-step]' );
			for ( let i = 0; i < elements.length; i++ ) {
				const element = elements[i];
				this.#observer.observe( element );
			}
		} );
	}

	disconnectedCallback() {
		this.#observer.disconnect();
	}

	#intersectionHandler = ( entries: IntersectionObserverEntry[] ) => {
		for ( let i = 0; i < entries.length; i++ ) {
			const entry = entries[i];
			this.#intersectionRatios.set( entry.target, entry.intersectionRatio );
		}

		let mostIntersectingElement: Element | null = null;
		let highestIntersectionRatio = 0;

		if ( window.scrollY < 200 ) {
			mostIntersectingElement = this.querySelector( '[data-form-step]' );
		} else {
			const elements = this.querySelectorAll( '[data-form-step]' );
			for ( let i = 0; i < elements.length; i++ ) {
				const element = elements[i];
				this.#observer.observe( element );

				const intersectionRatio = this.#intersectionRatios.get( element ) ?? 0;

				if ( intersectionRatio > highestIntersectionRatio ) {
					mostIntersectingElement = element;
					highestIntersectionRatio = intersectionRatio;
				}
			}
		}

		if ( !mostIntersectingElement ) {
			return;
		}

		if ( !( mostIntersectingElement instanceof HTMLElement ) ) {
			return;
		}

		const stepIndex = mostIntersectingElement.getAttribute( 'data-form-step' );
		if ( !stepIndex ) {
			return;
		}

		{
			const progressElement = this.querySelector( `[data-form-step-progress="${stepIndex}"]` );
			if ( !progressElement || progressElement.hasAttribute( 'data-form-step-progress-is-active' ) ) {
				return;
			}

			const otherProgressElements = this.querySelectorAll( `[data-form-step-progress]:not([data-form-step-progress="${stepIndex}"])` );
			for ( let i = 0; i < otherProgressElements.length; i++ ) {
				otherProgressElements[i].removeAttribute( 'data-form-step-progress-is-active' );
			}

			progressElement.setAttribute( 'data-form-step-progress-is-active', '' );
		}
	};
}

customElements.define( 'mr-form-step-progress', MrFormStepProgress );
