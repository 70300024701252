import { bugsnagClient } from '@mrhenry/wp--bugsnag-config';

export const getRoomDynamicDataFromInlineJSON = () => {
	const el = document.getElementById( 'room-dynamic-data-json' );
	if ( !el ) {
		return;
	}

	let roomsData = null;

	try {
		roomsData = JSON.parse( el.innerHTML );
	} catch ( e ) {
		bugsnagClient.notify( e );
	}

	return roomsData;
};

let roomsData = null;
const getRoomData = ( room_id = '1', subroom_id = '0', key ) => {
	if ( !key ) {
		return;
	}

	const roomId = `${room_id}`;
	const subRoomId = `${subroom_id}`;

	if ( !roomsData ) {
		roomsData = getRoomDynamicDataFromInlineJSON();
	}

	for ( let i = 0; i < roomsData.length; i++ ) {
		const room = roomsData[i];
		if ( `${room.room_id}` !== roomId ) {
			continue;
		}

		if ( `${room.subroom_id}` !== subRoomId ) {
			continue;
		}

		return room[key] || null;
	}

	return '';
};

export const getRoomName = ( room_id = '1', subroom_id = '0' ) => {
	return getRoomData( room_id, subroom_id, 'name' );
};

export const getRoomPricelist = ( room_id = '1', subroom_id = '0' ) => {
	return getRoomData( room_id, subroom_id, 'room_price_list' );
};

export const getRoomCateringPricelist = ( room_id = '1', subroom_id = '0' ) => {
	return getRoomData( room_id, subroom_id, 'catering_price_list' );
};

export const getCanHaveAStudentEntrepreneurialEvent = ( room_id = '1', subroom_id = '0' ) => {
	return getRoomData( room_id, subroom_id, 'can_have_a_student_entrepreneurial_event' );
};

export const getRoomDrinksPricelist = ( room_id = '1', subroom_id = '0' ) => {
	return getRoomData( room_id, subroom_id, 'drinks_price_list' );
};

export const getRoomTermsAndConditions = ( room_id = '1', subroom_id = '0' ) => {
	return getRoomData( room_id, subroom_id, 'terms_and_conditions' );
};
