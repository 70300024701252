import { CloneList } from '../base/clone-list.js';

class MrActivityList extends CloneList {

	constructor() {
		// If you define a constructor, always call super() first!
		// This is specific to CE and required by the spec.
		super();

		this._stateHandler = ( e ) => {
			if ( !e || !e.detail || !e.detail.step_type ) {
				return;
			}

			const state = e.detail.step_type;

			if ( !state.activities ) {
				return;
			}

			this.setAttribute( 'value', state.activities );
		};
	}

	// Life cycle
	connectedCallback() {
		this._addEventListeners();
	}

	disconnectedCallback() {
		this._removeEventListeners();
	}

	_addEventListeners() {
		window.addEventListener( 'rent-space:state-change', this._stateHandler );
	}

	_removeEventListeners() {
		window.removeEventListener( 'rent-space:state-change', this._stateHandler );
	}

	update() {
		const data = this.value;
		if ( !data ) {
			return;
		}

		this.resize( data.length, ( i ) => {
			return {
				input: [
					{
						id: `activity--${i}`,
						value: data[i].activity_id,
					},
				],
				label: [
					{
						innerHTML: data[i].name,
						for: `activity--${i}`,
					},
				],
			};
		} );
	}
}

customElements.define( 'mr-activity-list', MrActivityList );
