class MrSummary extends HTMLElement {

	constructor() {
		super();

		this._stateHandler = ( e ) => {
			if ( !e || !e.detail ) {
				return;
			}

			if ( e.detail.submit_data ) {
				const state = e.detail.submit_data;

				for ( const key in state ) {
					if ( !state.hasOwnProperty( key ) ) {
						continue;
					}

					if (
						'periods' === key ||
						'facilities' === key
					) {
						continue;
					}

					this.setSingleInnerHTML( state, key );
				}
			}

			if ( e.detail.step_confirm ) {
				const state = e.detail.step_confirm;

				for ( const key in state ) {
					if ( !state.hasOwnProperty( key ) ) {
						continue;
					}

					if (
						'periods' === key ||
						'facilities' === key
					) {
						continue;
					}

					this.setSingleInnerHTML( state, key );
				}
			}
		};
	}

	setSingleInnerHTML( state, key ) {
		if ( !state[key] ) {
			return;
		}

		const el = document.getElementById( `summary-${key}` );
		if ( !el ) {
			return;
		}

		if ( el.id === 'summary-rrnr' && state[key].trim() === 'x' ) {
			el.innerHTML = '';

			return;
		}

		if ( el.id === 'summary-vat' && state[key].trim() === 'x' ) {
			el.innerHTML = '';

			return;
		}

		el.innerHTML = state[key];
	}

	// Life cycle
	connectedCallback() {
		this._addEventListeners();
	}

	disconnectedCallback() {
		this._removeEventListeners();
	}

	_addEventListeners() {
		window.addEventListener( 'rent-space:state-change', this._stateHandler );
	}

	_removeEventListeners() {
		window.removeEventListener( 'rent-space:state-change', this._stateHandler );
	}
}

customElements.define( 'mr-summary', MrSummary );
