import { CloneList } from '../base/clone-list.js';

class MrFacilityList extends CloneList {

	constructor() {
		// If you define a constructor, always call super() first!
		// This is specific to CE and required by the spec.
		super();

		this._stateHandler = ( e ) => {
			if ( !e || !e.detail || !e.detail.step_room ) {
				return;
			}

			const state = e.detail.step_room;

			if ( !state.rooms ) {
				return;
			}

			state.rooms.forEach( ( room ) => {
				if ( !room.selected ) {
					return;
				}

				if ( !room.facilities ) {
					return;
				}

				this.setAttribute( 'value', room.facilities );
			} );
		};
	}

	// Life cycle
	connectedCallback() {
		this._addEventListeners();
	}

	disconnectedCallback() {
		this._removeEventListeners();
	}

	_addEventListeners() {
		window.addEventListener( 'rent-space:state-change', this._stateHandler );
	}

	_removeEventListeners() {
		window.removeEventListener( 'rent-space:state-change', this._stateHandler );
	}

	update() {
		const data = this.value;
		if ( !data ) {
			return;
		}

		this.resize( data.length, ( i ) => {
			return {
				label: [
					{
						innerHTML: data[i].name,
						for: `facility--${i}`,
					},
				],
				input: [
					{
						value: data[i].facility_id,
						checked: false,
						id: `facility--${i}`,
						name: 'facilities[]',
					},
				],
			};
		} );
	}
}

customElements.define( 'mr-facility-list', MrFacilityList );
