import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';

defineCustomElement( 'mr-datetime', {
	attributes: [
		{
			attribute: 'language',
			type: 'string',
		},
		{
			attribute: 'for',
			type: 'string',
		},
	],
	controller: class extends BaseController {

		init() {
			this.months = [
				{
					en: 'january',
					nl: 'januari',
				},
				{
					en: 'february',
					nl: 'februari',
				},
				{
					en: 'march',
					nl: 'maart',
				},
				{
					en: 'april',
					nl: 'april',
				},
				{
					en: 'may',
					nl: 'mei',
				},
				{
					en: 'june',
					nl: 'juni',
				},
				{
					en: 'july',
					nl: 'juli',
				},
				{
					en: 'august',
					nl: 'augustus',
				},
				{
					en: 'september',
					nl: 'september',
				},
				{
					en: 'october',
					nl: 'oktober',
				},
				{
					en: 'november',
					nl: 'november',
				},
				{
					en: 'december',
					nl: 'december',
				},
			];
		}

		resolve() {
			return Promise.resolve( true );
		}

		render() {
			window.requestAnimationFrame( () => {
				this.renderOnce();
				this.el.classList.add( 'is-rendered' );

				const targetedEl = this.targetedEl;
				if ( targetedEl ) {
					targetedEl.classList.add( 'datetime-is-rendered' );
				}
			} );
		}

		bind() {
			this.tickHandler = () => {
				this.renderOnce();
			};

			window.addEventListener( 'mr-datetime:tick', this.tickHandler, false );
		}

		unbind() {
			if ( !this.tickHandler ) {
				return;
			}

			window.removeEventListener( 'mr-datetime:tick', this.tickHandler, false );
		}

		renderOnce() {
			if ( !this || !this.el ) {
				return;
			}

			this.renderMinute();
			this.renderHour();
			this.renderDay();
			this.renderMonth();
		}

		renderMinute() {
			if ( !this.minuteEl ) {
				return;
			}

			const update = ( '0' + ( new Date() ).getMinutes() ).slice( -2 );
			if ( update === this.minuteLastInnerHTML ) {
				return;
			}

			this.minuteEl.innerHTML = update;
			this.minuteLastInnerHTML = update;

			return;
		}

		renderHour() {
			if ( !this.hourEl ) {
				return;
			}

			const update = ( '0' + ( new Date() ).getHours() ).slice( -2 );
			if ( update === this.hourLastInnerHTML ) {
				return;
			}

			this.hourEl.innerHTML = update;
			this.hourLastInnerHTML = update;

			return;
		}

		renderDay() {
			if ( !this.dayEl ) {
				return;
			}

			const update = ( '0' + ( new Date() ).getDate() ).slice( -2 );
			if ( update === this.dayLastInnerHTML ) {
				return;
			}

			this.dayEl.innerHTML = update;
			this.dayLastInnerHTML = update;

			return;
		}

		renderMonth() {
			if ( !this.months ) {
				return;
			}

			if ( !this.monthEl ) {
				return;
			}

			const month = this.months[( new Date() ).getMonth()] || [];
			const update = month[this.language] || '';

			if ( update === this.monthLastInnerHTML ) {
				return;
			}

			const targetedEl = this.targetedEl;
			if ( targetedEl ) {
				targetedEl.setAttribute( 'data-month', update );
			}

			this.monthEl.innerHTML = update;
			this.monthLastInnerHTML = update;

			return;
		}

		get minuteEl() {
			if ( this._minuteEl ) {
				return this._minuteEl;
			}

			const el = this.el.querySelector( '.js-datetime-minute' );
			if ( !el ) {
				return null;
			}

			this._minuteEl = el;

			return this._minuteEl;
		}

		get hourEl() {
			if ( this._hourEl ) {
				return this._hourEl;
			}

			const el = this.el.querySelector( '.js-datetime-hour' );
			if ( !el ) {
				return null;
			}

			this._hourEl = el;

			return this._hourEl;
		}

		get dayEl() {
			if ( this._dayEl ) {
				return this._dayEl;
			}

			const el = this.el.querySelector( '.js-datetime-day' );
			if ( !el ) {
				return null;
			}

			this._dayEl = el;

			return this._dayEl;
		}

		get monthEl() {
			if ( this._monthEl ) {
				return this._monthEl;
			}

			const el = this.el.querySelector( '.js-datetime-month' );
			if ( !el ) {
				return null;
			}

			this._monthEl = el;

			return this._monthEl;
		}

		get targetedEl() {
			if ( !this.for ) {
				return null;
			}

			const targetedEl = document.querySelector( '#' + this.for );
			if ( !targetedEl ) {
				return null;
			}

			return targetedEl;
		}
	},
} );

// Start a ticker as soon as possible.
// Custom elements start listening when that are attached to DOM.
( function() {
	setInterval( () => {
		window.requestAnimationFrame( () => {
			window.dispatchEvent( new Event( 'mr-datetime:tick' ) );
		} );
	}, 1000 * 2 );
}() );
